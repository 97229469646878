<template>
  <div>
    <CCard class="p-0 m-0">
      <CCardHeader class="py-1">
<!--        <label class="header-title">お知らせ登録</label>-->
        <label class="header-title">お知らせ登録{{isCollapsed ?"(一覧モード)":"(登録モード)"}}</label>
        <div class="card-header-actions">
          <CButton size="sm" color="success mr-1" variant="outline" :pressed="toggleA" @click="toggleButton('A')">アドミニストレータ</CButton>
          <CButton size="sm" color="info mr-1" variant="outline" :pressed="toggleR" @click="toggleButton('R')">レジストレータ</CButton>
          <CButton size="sm" color="warning mr-1" variant="outline" :pressed="toggleM" @click="toggleButton('M')">メンテナンス</CButton>
          <CButton size="sm" color="primary ml-5 mr-1" variant="outline" :pressed="toggleP" @click="toggleButton('P')">プロバイダ</CButton>
          <CButton size="sm" color="dark mr-1" variant="outline" :pressed="toggleD" @click="toggleButton('D')">医療関係者</CButton>


          <CButton color="primary" size="sm" @click="isCollapsed=!isCollapsed">{{isCollapsed ? '登録':'一覧'}}モードへ</CButton>
<!--          <CLink class="card-header-action btn-minimize" @click="isCollapsed = !isCollapsed">-->
<!--            <CIcon :name="`cil-chevron-${isCollapsed ? 'top':'bottom'}`" style="color: deeppink"/>-->
<!--          </CLink>-->
        </div>


      </CCardHeader>

      <CCardBody class="p-0">
        <div>
          <CCollapse :show="!isCollapsed" :duration="200">
            <CCard class="notice_form p-0 m-0">
              <CCardBody class="notice_form ">
                <CForm>
                  <CRow>
                    <CCol>お知らせ先
<!--                      <CBadge color="success" class="ml-2" v-if="formData.id != null">ID:{{ formData.id }}</CBadge>-->
                    </CCol>
                  </CRow>
                  <CRow class="mb-2">
                    <CCol>
                      <CCol class="form-inline">
                        <div role="group" class="mr-5 form-check form-check-inline"
                             v-for="(option, optionIndex) in targets"
                             :key="option + optionIndex">
                          <input type="checkbox"
                                 class="form-check-input"
                                 :value="option.value"
                                 :id="option.value"
                                 inline
                                 v-model="formData.selectedTarget"/>
                          <label :for="option.value" class="form-check-label">{{ option.label }} </label>
                        </div>
                      </CCol>
                    </CCol>
                  </CRow>
                  <CRow class="mb-2">
                    <CCol>
                      <CInput label="件名"
                              placeholder="入力してください" v-model="formData.title"/>
                    </CCol>
                  </CRow>
                  <CRow class="mb-2">
                    <CCol>
                      <CTextarea
                          label="お知らの文章"
                          placeholder="入力してください"
                          rows="9" v-model="formData.content"/>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <h4>掲示日</h4>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="2" class="form-inline">
                      <datepicker id="from" :value="formData.fromDate" :language="ja" style="width:100%"
                                  v-model="formData.fromDate" format="yyyy/MM/dd" bootstrap-styling
                                  typeable placeholder="いつから"/>

                    </CCol>
                    <CCol col="2" class="form-inline">
                      <datepicker id="to" :value="formData.toDate" :language="ja" style="width:100%"
                                  v-model="formData.toDate" format="yyyy/MM/dd" bootstrap-styling
                                  typeable placeholder="いつまで"/>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
              <CCardFooter>
                <SaveButtons :id="formData.id" @formInit="formInit" @save="save"/>
              </CCardFooter>
            </CCard>
          </CCollapse>
<!--          v-if="isCollapsed"-->
          <div >
              <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                              :curr-page.sync="pagingParams.currPage"
                              :per-page.sync="pagingParams.perPage"
                              :per-page-list="perPageList"
                              :pages="pages"
                              :search-bar-place-holder="searchBarPlaceHolder"
                              :keyword.sync="pagingParams.keyword">
                <template slot="tools">
                </template>
              </MyBeeSearchBar>

              <BeeGridTable
                  ref="bee"
                  border
                  stripe

                  :showFilter="false"
                  :columns="columns"
                  :loading="isLoading"
                  :data="rows"
                  :showPager="false"
                  :maxHeight="gridCardHeight -30"
                  highlightRow
                  no-data-text="データがありません。"
                  no-filtered-data-text=""
                  :context="context"
                  :span-method="customSpan"
                  :showVerticalScrollBar="false"
                  :showHorizontalScrollBar="false">
                <!-- HEADER ソート可能にするために 必要-->
                <template slot-scope="{ column,index }" slot="idHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
                <template slot-scope="{ column,index }" slot="createrNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
                <template slot-scope="{ column,index }" slot="titleHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
<!--                <template slot-scope="{ column,index }" slot="targetHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>-->
                <template slot-scope="{ column,index }" slot="fromDateHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
                <template slot-scope="{ column,index }" slot="dispFlgHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
                <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
                <template slot-scope="{ column,index }" slot="afterHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>

                <!-- cell -->

                <template slot-scope="{ row,index }" slot="title">
                  <div class="text-left">{{row.title}}</div>
                </template>

                <template slot-scope="{ row,index }" slot="createrName">
                  <div class="float-left">{{row.createrName}}</div>
                </template>

<!--                <template slot-scope="{ row,index }" slot="creatorName">-->
<!--                  <div class="float-left">{{row.createrName}}</div>-->
<!--                </template>-->

                <template slot-scope="{ row,index }" slot="created">
                  <div class="float-left">{{row.created}}</div>
                </template>


                <template slot-scope="{ row,index }" slot="target">
                  <div class="float-left">
                      <CBadge color="success" class="ml-2" v-if="row.targetAdmin">アドミニストレータ</CBadge>
                      <CBadge color="info" class="ml-2" v-if="row.targetRegistrator">レジストレータ</CBadge>
                      <CBadge color="warning" class="ml-2" v-if="row.targetMaintenance">メンテナンス</CBadge>
                      <CBadge color="primary" class="ml-2" v-if="row.targetProvider">プロバイダ</CBadge>
                      <CBadge color="dark" class="ml-2" v-if="row.targetDoctor">医療関係者</CBadge>
                  </div>
                </template>


                <template slot-scope="{ row,index }" slot="fromDate">
                  <div class="text-left">
                    {{row.fromDate}}から{{row.toDate}}
                  </div>
                </template>

                <template slot-scope="{ row,index }" slot="dispFlg">
                  <div class="text-center">
                      <CBadge color="dark" v-if="row.dispFlg == 'before'">表示完</CBadge>
                      <CBadge color="info" v-else-if="row.dispFlg == 'ing'">表示中</CBadge>
                      <CBadge color="primary" v-else>表示予定</CBadge>
                  </div>
                </template>

                <template slot-scope="{ row,index }" slot="after">
                  <div class="float-left">
                      <CButton color="success py-0" @click="update(row)">編集</CButton>
                      <CButton color="danger py-0 ml-1" @click="deleteData(row)">削除</CButton>
                  </div>
                </template>
              </BeeGridTable>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import moment from 'moment'
//import Datetime from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import SaveButtons from "@/component/parts/SaveButtons";
import datepicker from 'vuejs-datepicker';
import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

import {ja} from "vuejs-datepicker/dist/locale";
export default {
  name: 'NoticeRegist',
  mixins: [BeeMix],
  components: {
    SaveButtons,datepicker,  MyBeeHeader, MyBeeSearchBar
  }, computed: {
    ja() {
      return ja;
    }
  },
  watch: {
    'formData.fromDate': function () {
      if (this.formData.fromDate) {
        this.formData.fromDate = moment(this.formData.fromDate).format('YYYY/MM/DD');
      }
    },'formData.toDate': function () {
      if (this.formData.toDate) {
        this.formData.toDate = moment(this.formData.toDate).format('YYYY/MM/DD');
      }
    },
    isCollapsed:function(newValue){
      if(newValue){
        this.formInit();
      }
    }
  },
  data() {
    return {
      requestDate: '',
      columns: [
        {key: 'id',title: 'No',width: 80,headSlot:"idHead"},
        {key: 'dispFlg',slot:"dispFlg",headSlot:"dispFlgHead", title: '表示', width: 80,align:"center"},
        {key: 'title', title: '件名',slot:'title',headSlot:"titleHead",align:"center"},
        {key: 'created',slot:'created',headSlot:"createdHead", title: '登録日時',align:"center"},
        {key: 'target',slot:"target", title: 'お知らせ先',align:"center"},
        {key: 'fromDate',slot:"fromDate",headSlot:"fromDateHead", title: 'お知らせの期間',align:"center"},
        {key: 'createrName', title: '作成者',slot:'createrName',headSlot:"createrNameHead",align:"center"},
        {key: 'after',slot:"after",width:140,title:' ',align: 'center'},
      ],
      formData: {id: null, selectedTarget: [], title: null, content: null, fromDate: null, toDate: null},
      targets: [
          {label: "アドミニストレータ", value: 'targetAdmin'},
          {label: "レジストレータ", value: 'targetRegistrator'},
          {label: "メンテナンス", value: 'targetMaintenance'},
          {label: "プロバイダ",value: 'targetProvider'},
          {label: '医療関係者', value: 'targetDoctor'}
          ],
      buttonSuper: "light",
      buttonRegister: "light",
      buttonMaintenance: "light",
      buttonProvider: "light",
      buttonDoctor: "light",
      toggleR:false,
      toggleA:false,
      toggleM:false,
      toggleP:false,
      toggleD:false,
      isCollapsed:true,
      searchBarPlaceHolder:"件名、作成者で検索",
      pagingParams: Object.assign(BeeMix.data().pagingParams,{sort: [{field: "disp_num", sort: "asc"}]}),
    }
  },
  mounted() {
  },
  methods:{
    toggleButton: function (type) {

      if (type == 'A') {
        this.toggleA = !this.toggleA;
      } else if (type == 'R') {
        this.toggleR = !this.toggleR;
      } else if (type == 'M') {
        this.toggleM = !this.toggleM;
      } else if (type == 'P') {
        this.toggleP = !this.toggleP;
      } else if (type == 'D') {
        this.toggleD = !this.toggleD;
      }
      this.goodPaging();
    },
    getRoles(){
      let list = [];
      if (this.toggleA) {
        list.push("targetAdmin");
      }
      if (this.toggleR) {
        list.push("targetRegistrator");
      }
      if (this.toggleM) {
        list.push("targetMaintenance");
      }
      if (this.toggleP) {
        list.push("targetProvider");
      }
      if (this.toggleD) {
        list.push("targetDoctor");
      }
      return list;
    },
    formInit: function () {
      this.formData = {selectedTarget: [], title: null, content: null, fromDate: null, toDate: null};
      this.requestDate = '';
    },
    goodPaging(isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;
      this.axios.post('/api/notice/pagingForRegist', {
        searchTargets: this.getRoles(),
        pagingForm: this.pagingParams
      }, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.searchParams = searchParams;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
          this.isLoading = false;
      });
    },
    back(){
      this.$router.back();
    }
    ,save() {
        for (let i in this.targets) {
          let target = this.targets[i];
          this.formData[target.value] = this.formData.selectedTarget.indexOf(target.value) != -1;
        }
        this.axios.post(`/api/notice/save`,this.formData, {headers: {'Content-Type': 'application/json'}}).then(response => {
          this.goodPaging();
        }).catch(error => {
          console.log('error', error);
        }).finally(() => {
          console.log('finally');
        });

    }, deleteData(data) {
      console.log("deleteData(data)", JSON.stringify(data));
      this.$swal({
        title: "削除しますか？",
        //text: "削除しますか？",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
          .then((willDelete) => {
            if (willDelete) {
              this.axios.post(`/api/notice/delete/${data.id}`, {}, {headers: {'Content-Type': 'application/json'}}
              ).then(response => {
                this.goodPaging();
              }).catch(error => {
                console.log("error", error);
              }).finally(() => {
                console.log('finally');
              });
            }
          });

    }
    , update(data) {
       this.axios.get(`/api/notice/get/${data.id}`).then(response => {
        let selected = [];
        console.log("update(JSON)", JSON.stringify(response));
        // if (response.data.result.targetAdminRegistratorMaintenance) {
        //   selected.push('targetAdminRegistratorMaintenance');
        // }
        if (response.data.result.targetAdmin) {
          selected.push('targetAdmin');
        }
        if (response.data.result.targetRegistrator) {
          selected.push('targetRegistrator');
        }
        if (response.data.result.targetMaintenance) {
          selected.push('targetMaintenance');
        }
        if (response.data.result.targetProvider) {
          selected.push('targetProvider');
        }
        if (response.data.result.targetDoctor) {
          selected.push('targetDoctor');
        }
        this.formData = Object.assign({}, response.data.result);
        this.formData.selectedTarget = selected;
        //this.requestDate = {start:response.data.result.fromDate,end:response.data.result.toDate};
        this.isCollapsed = false;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    }
  }
}
</script>
<style scoped>
.notice_form * {
  font-size: 1.1rem;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  /*white-space: -pre-wrap;      !* Opera 4-6 *!*/
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.vgt-global-search__actions .badge:hover {
  cursor: pointer;
}

</style>

<style>
.grey{
  background-color: grey;
}
/*.vs__search{*/
/*  padding-top:5px;*/
/*  padding-bottom:5px;*/
/*}*/
</style>
